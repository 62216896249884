import React, { FC, useMemo } from 'react'
import styles from './Home.module.scss'
import { GridItem, Page } from 'modules/Layout/components'
import { FlexColumn } from 'components/FlexColumn'
import { Orders } from './Orders'
import { Insights } from './Insights'
import { Devices, DeviceCardSizes } from './Devices'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { useMediaQuery } from 'hooks/use-media-query'
import { Feedback } from './Feedback'
import { getIsFeedbackEnabled } from 'utils/app'
import { WebAppScreens, Event } from 'analytics-events'
import { Claims } from './Claims'
import {
  CAROUSEL_SLIDE_GAP,
  DEFAULT_HOME_VIEW,
  HOME_MEDIA_QUERIES,
  HOME_VIEWS,
  CarouselResponsiveProps,
} from './config'

export const Home: FC = () => {
  // context
  const { region, isHarmReductionClaimsEnabled } = useConxSdk()
  const { userAgent } = useConxSdk()

  // responsive data
  const { size } = useMediaQuery<{ size: DeviceCardSizes }>(
    HOME_MEDIA_QUERIES,
    HOME_VIEWS,
    DEFAULT_HOME_VIEW,
  )
  const isNarrowView = size <= 2
  const carouselResponsiveProps: CarouselResponsiveProps = {
    allowTouchMove: !userAgent?.isDesktop,
    paginationStyle: userAgent?.isDesktop ? 'dots' : 'dashes',
    peepAdjacent: isNarrowView,
    spaceBetween: isNarrowView ? CAROUSEL_SLIDE_GAP.narrow : CAROUSEL_SLIDE_GAP.default,
  }

  // events
  const screenEvent = useMemo<Event>(() => WebAppScreens.HomeEvent(), [])

  // feature flags
  const isOrdersEnabled = process.env.NEXT_PUBLIC_ECOMM_ENABLED_REGIONS?.includes(region)
  const isFeedbackEnabled = getIsFeedbackEnabled()

  return (
    <GridItem verticalAlign='top'>
      <Page className={styles.Home} screenEvent={screenEvent}>
        <FlexColumn spacing={{ default: 'extraExtraLoose' }}>
          <Devices
            carouselResponsiveProps={carouselResponsiveProps}
            size={size}
          />
          {isHarmReductionClaimsEnabled && (
            <Claims carouselResponsiveProps={carouselResponsiveProps} />
          )}
          <Insights />
          {isOrdersEnabled && <Orders />}
          {isFeedbackEnabled && <Feedback />}
        </FlexColumn>
      </Page>
    </GridItem>
  )
}
