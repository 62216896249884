import { WebAppCards, WebAppScreens } from 'analytics-events'
import { Survey } from 'modules/Home/components/SurveyCard'

export const surveyClaimsPreference: Survey = {
  options: [
    {
      clickEvent: WebAppScreens.Home.ClickNo_WouldLikeMoreClaimsContent(),
      content: 'No',
    },
    {
      clickEvent: WebAppScreens.Home.ClickYes_WouldLikeMoreClaimsContent(),
      content: 'Yes',
    },
  ],
  prompt: { content: 'Do you want to see more content like this in the app?' },
  surveyCardEvent: WebAppCards.ClaimsSurveyCardEvent({
    cardState: WebAppCards.SurveyCardState.WouldLikeMoreClaimsContent,
    screenName: WebAppCards.ScreenName.Home,
  }),
}

export const surveyClaimsHelpfulness: Survey = {
  options: [
    {
      clickEvent: WebAppScreens.Home.ClickNo_WasClaimsContentHelpful(),
      content: 'No',
    },
    {
      clickEvent: WebAppScreens.Home.ClickYes_WasClaimsContentHelpful(),
      content: 'Yes',
    },
  ],
  prompt: { content: 'Did you find this content helpful?' },
  surveyCardEvent: WebAppCards.ClaimsSurveyCardEvent({
    cardState: WebAppCards.SurveyCardState.WasClaimsContentHelpful,
    screenName: WebAppCards.ScreenName.Home,
  }),
}

export const claimsSurveyThankYou: Survey = {
  prompt: { content: 'Thank you for your feedback.' },
  surveyCardEvent: WebAppCards.ClaimsSurveyCardEvent({
    cardState: WebAppCards.SurveyCardState.ThankYou,
    screenName: WebAppCards.ScreenName.Home,
  }),
}

export const surveys: Survey[] = [
  surveyClaimsPreference,
  surveyClaimsHelpfulness,
  claimsSurveyThankYou,
]
