import { TextFormats } from 'components/Text'
import { DeviceButtonProps } from '../DeviceButton'
import { WebAppScreens } from 'analytics-events'

export type DisconnectedData = {
  alert: TextFormats
  alertButton: DeviceButtonProps
  button: DeviceButtonProps
  caption: TextFormats
}

export const disconnectedData: DisconnectedData = {
  alert: { content: 'This device is in danger of losing your data. Please reconnect to sync your usage data.' },
  alertButton: {
    clickEvent: WebAppScreens.Home.ClickReconnect_DisconnectedWithAlert_DeviceCard(),
    content: 'Reconnect',
  },
  button: {
    clickEvent: WebAppScreens.Home.ClickReconnect_Disconnected_DeviceCard(),
    content: 'Reconnect',
  },
  caption: { content: 'Last connected %{timeSinceConnected}.' },
}
