import { TextFormats } from 'components/Text'
import { DeviceButtonProps } from '../DeviceButton'
import { WebAppScreens } from 'analytics-events'

export type UnauthenticatedData = {
  button: DeviceButtonProps
  subtitle: TextFormats
  title: TextFormats
}

export const unauthenticatedData: UnauthenticatedData = {
  button: {
    clickEvent: WebAppScreens.Home.ClickConfirmConnection_Unauthenticated_DeviceCard(),
    content: 'Confirm connection',
  },
  subtitle: { content: 'Your JUUL2 Device will disconnect from any other JUUL apps.' },
  title: { content: 'Re-authenticate connection.' },
}
