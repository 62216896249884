import React, { FC } from 'react'
import { useSwiperSlide } from 'swiper/react'
import styles from './ClaimsCard.module.scss'
import { classNames } from 'utils/css'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { Text, TextFormats } from 'components/Text'
import { Box } from 'components/Box'
import { Event } from 'analytics-events'

export type Claim = {
  claim: {
    p1: TextFormats
    p2: TextFormats
  }
  claimsCardEvent?: Event
  description: TextFormats
  disclaimer: TextFormats
  heading: TextFormats
}

export type ClaimsCardProps = TileProps & Claim

export const ClaimsCard: FC<ClaimsCardProps> = ({
  claim,
  claimsCardEvent,
  className,
  description,
  disclaimer,
  heading,
  ...rest
}) => {
  const { isActive } = useSwiperSlide()

  return (
    <Tile
      className={classNames(styles.ClaimsCard, className)}
      tileEvent={isActive && claimsCardEvent}
      {...rest}
    >
      <Box className={styles.topSection}>
        <Text
          className={styles.heading}
          message={heading}
          tagName='h3'
          typography='h1Mobile'
        />
        <Box className={styles.claim}>
          <Text
            message={claim.p1}
            tagName='p'
            typography='h4Mobile'
          />
          <Text
            message={claim.p2}
            tagName='p'
            typography='h4Mobile'
          />
        </Box>
      </Box>
      <Box className={styles.bottomSection}>
        <Text
          message={description}
          tagName='p'
          typography='captionBase'
          color='ink|base|75'
        />
        <Text
          message={disclaimer}
          tagName='p'
          typography='captionBase'
          color='ink|base|75'
        />
      </Box>
    </Tile>
  )
}
