import React, { FC, useEffect } from 'react'
import { useSwiperSlide } from 'swiper/react'
import { Text } from 'components/Text'
import { BackgroundTile, BackgroundTileProps } from '../BackgroundTile'
import { DeviceButton } from '../DeviceButton'
import { newDeviceCardData } from './localization'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { WebAppCards } from 'analytics-events'

export interface NewDeviceCardProps extends Omit<BackgroundTileProps, 'colors'> {
  /** callback to start the pair flow */
  connectNewDevice(): void
  /** static identifying parameters for NewDeviceCard events */
  newDeviceCardEventParams?: {
    /** name of the screen the NewDeviceCard renders on */
    screenName: WebAppCards.ScreenName
  }
}

export const NewDeviceCard: FC<NewDeviceCardProps> = ({
  connectNewDevice,
  newDeviceCardEventParams,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()
  const { isActive } = useSwiperSlide()
  const { heading, instruction, button } = newDeviceCardData

  // todo - eventually incorporate 'newDevice' | 'welcome' state, post removal of the pair page
  useEffect(() => {
    if (newDeviceCardEventParams && isActive) {
      reportEvent(WebAppCards.NewDeviceCardEvent({ ...newDeviceCardEventParams }))
    }
  }, [isActive])

  return (
    <BackgroundTile
      data-analytics-id={WebAppCards.CardName.NewDeviceCard}
      tagName='article'
      {...rest}
    >
      <Text
        color='titanium|base|100'
        message={heading}
        tagName='h3'
        typography='h3'
      />
      <Text
        color='titanium|base|50'
        message={instruction}
        tagName='p'
        typography='bodyBaseBold'
      />
      <DeviceButton
        {...button}
        disabled={!isActive}
        onClick={connectNewDevice}
      />
    </BackgroundTile>
  )
}
