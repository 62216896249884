import React, { ComponentPropsWithoutRef, FC } from 'react'
import styles from './Pagination.module.scss'
import { classNames, variationName } from 'utils/css'
import { UnstyledButton } from 'components/UnstyledPrimitives'

export interface PaginationProps extends ComponentPropsWithoutRef<'ol'> {
  /** prefix for the aria label on each pagination element */
  accessibilityLabelPrefix?: string
  /** index of the active slide */
  activeIndex: number
  /** callback for pagination button onClick */
  handlePagination(index: number): void
  /** number of slide for pagination */
  numberOfSlides: number
  /** pagination button style:
   *  dots are good for desktop, where dashes are better for touch screens */
  paginationStyle?: 'dashes' | 'dots'
}

export const Pagination: FC<PaginationProps> = ({
  accessibilityLabelPrefix = 'Slide',
  activeIndex,
  className,
  handlePagination,
  numberOfSlides,
  paginationStyle = 'dots',
  ...rest
}) => (
  <ol className={classNames(
    styles.Pagination,
    styles[variationName('style', paginationStyle)],
    className,
  )} {...rest}>
    {Array.from({ length: numberOfSlides }, (_, i) => (
      <li className={styles.paginationItem} key={`slide-${i+1}`}>
        <UnstyledButton
          aria-label={`${accessibilityLabelPrefix} ${i+1}`}
          className={classNames(
            styles.paginationButton,
            activeIndex === i && styles.active,
          )}
          disabled={activeIndex === i}
          onClick={() => handlePagination(i)}
        />
      </li>
    ))}
  </ol>
)
