import React, { FC } from 'react'
import styles from './SectionLink.module.scss'
import { Icon } from '@juullabs/react-components'
import { UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { classNames } from 'utils/css'

export const SectionLink: FC<UnstyledLinkProps> = ({
  disabled,
  ...rest
}) => (
  <UnstyledLink
    className={classNames(
      styles.SectionLink,
      disabled && styles.disabled,
    )}
    disabled={disabled}
    {...rest}
  >
    <span>View All</span>
    <Icon
      className={styles.linkIcon}
      color={disabled ? 'steel|base|30' : 'ink|base|50'}
      name='icon/24x24/chevron_base_right'
    />
  </UnstyledLink>
)
