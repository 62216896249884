export interface DeviceCardChild {
  inactive?: boolean
}

export type DeviceCardSizes = 1 | 2 | 3 | 4 | 5

export enum DeviceCardImages {
  PartialDevice = '/images/home/juul2-partial-device.png',
  PartialDeviceLocked = '/images/home/juul2-partial-device-locked.png',
  Pod = '/images/home/juul2-pod.png',
}

export enum DeviceCardPlaceholders {
  PartialDevice = 'data:juul2-partial-device;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAFCAYAAABvsz2cAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADBJREFUeNpiXLN+1/fff34zsrBzcHD8/8HAwPT//3+G///+MTD9/fuXAYThDIAAAwAvdBn15g0C8QAAAABJRU5ErkJggg==',
  PartialDeviceLocked = 'data:juul2-partial-device-locked;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAFCAYAAABvsz2cAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADBJREFUeNpiXLtu5/c/f/4wsrBzcnL8//GDgenf//8M//79Y2D6+/cvAwjDGQABBgBLxBruMgQ66AAAAABJRU5ErkJggg==',
  Pod = 'data:juul2-pod;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAECAYAAACk7+45AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAC1JREFUeNpidHV1/fHz509GFkZGRiYGBgZGFiDBBOQwsnz//v3Rjx8/GAACDAC1fwt89F0pBAAAAABJRU5ErkJggg==',
}
