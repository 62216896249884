import { useState, useEffect } from 'react'
import { parseISO, differenceInDays, formatDistanceToNow, isAfter } from 'date-fns'
import { useInterval } from 'hooks/use-interval'

const LAST_CONNECTED_INTERVAL_MS = 60000 // 1 minute
const ALERT_CUTOFF_DAYS = 10 // 10 days
const CLOUD_ERROR_DEFAULT_TEXT = 'a while ago'

export interface LastConnectedData {
  /** alert message and icon,
   * associated with the device not having been paired in awhile */
  hasAlert: boolean
  /** span of time since the device has been connected */
  timeSinceConnected: string
}

export const useLastConnected = (lastConnected: string, isEnabled: boolean) => {
  // functions
  const formatLastConnected = (lastConnected: string): LastConnectedData => {
    const lastConnectedDate = parseISO(lastConnected)
    const referenceDate = new Date(2015, 0, 1) // January 1, 2015

    return ({
      hasAlert: differenceInDays(new Date(), lastConnectedDate) >= ALERT_CUTOFF_DAYS,
      timeSinceConnected: isAfter(lastConnectedDate, referenceDate)
        ? formatDistanceToNow(lastConnectedDate, { addSuffix: true })
        : CLOUD_ERROR_DEFAULT_TEXT,
    })
  }

  // state
  const defaultLastConnectedData: LastConnectedData = {
    hasAlert: null,
    timeSinceConnected: '',
  }

  const [
    lastConnectedData,
    setLastConnectedData,
  ] = useState<LastConnectedData>(isEnabled
    ? formatLastConnected(lastConnected)
    : defaultLastConnectedData)

  // effects
  useEffect(() => {
    if (isEnabled) setLastConnectedData(formatLastConnected(lastConnected))
    else setLastConnectedData(defaultLastConnectedData)
  }, [isEnabled, lastConnected])

  useInterval(() => {
    setLastConnectedData(formatLastConnected(lastConnected))
  }, isEnabled ? LAST_CONNECTED_INTERVAL_MS : null)

  return lastConnectedData
}
