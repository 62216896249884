import { TextFormats } from 'components/Text'
import { DeviceButtonProps } from '../DeviceButton'
import { WebAppScreens } from 'analytics-events'

export type ReconnectingData = {
  button: DeviceButtonProps
  spinnerLabel: string
  subtitle: TextFormats
  title: TextFormats
}

export const reconnectingData: ReconnectingData = {
  button: {
    clickEvent: WebAppScreens.Home.ClickCancel_Reconnecting_DeviceCard(),
    content: 'Cancel',
  },
  spinnerLabel: 'Reconnecting',
  subtitle: { content: 'Make sure your device is charged and nearby.' },
  title: { content: 'Attempting to reconnect...' },
}
