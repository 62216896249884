import { WebAppCards } from 'analytics-events'
import { Claim } from './ClaimsCard'
import { TextFormats } from 'components/Text'

const claimsDisclaimer: TextFormats = { content: 'JUUL2 is not risk-free and contains nicotine, which is a highly addictive substance.' }
const JUUL2Claim: TextFormats = { content: 'JUUL2 does not.' }

export const noSmoke: Claim = {
  claim: {
    p1: { content: 'Cigarettes burn tobacco and produce smoke.' },
    p2: JUUL2Claim,
  },
  claimsCardEvent: WebAppCards.ClaimsCardEvent({
    cardState: WebAppCards.ClaimsCardState.NoSmoke,
    screenName: WebAppCards.ScreenName.Home,
  }),
  description: { content: 'JUUL2 does not burn tobacco. JUUL2 heats a nicotine containing liquid to produce an inhalable vapour, not smoke.' },
  disclaimer: claimsDisclaimer,
  heading: { content: 'No smoke.' },
}

export const noCarbonMonoxide: Claim = {
  claim: {
    p1: { content: 'Cigarettes produce high amounts of carbon monoxide.' },
    p2: JUUL2Claim,
  },
  claimsCardEvent: WebAppCards.ClaimsCardEvent({
    cardState: WebAppCards.ClaimsCardState.NoCarbonMonoxide,
    screenName: WebAppCards.ScreenName.Home,
  }),
  description: { content: 'Cigarettes burn tobacco, which produces carbon monoxide. JUUL2 doesn’t burn tobacco. Scientific testing detected no carbon monoxide in JUUL2 vapour.' },
  disclaimer: claimsDisclaimer,
  heading: { content: 'No carbon monoxide.' },
}

export const noTar: Claim = {
  claim: {
    p1: { content: 'Cigarettes produce tar.' },
    p2: JUUL2Claim,
  },
  claimsCardEvent: WebAppCards.ClaimsCardEvent({
    cardState: WebAppCards.ClaimsCardState.NoTar,
    screenName: WebAppCards.ScreenName.Home,
  }),
  description: { content: '“Tar” is the particulate matter specific to cigarette smoke. JUUL2 heats a nicotine-containing liquid to produce an inhalable vapour, not cigarette smoke.' },
  disclaimer: claimsDisclaimer,
  heading: { content: 'No tar.' },
}

export const claims: Claim[] = [noSmoke, noCarbonMonoxide, noTar]
