import React, { FC, useState } from 'react'
import styles from './Unauthenticated.module.scss'
import { Text } from 'components/Text'
import { DeviceButton } from '../DeviceButton'
import { unauthenticatedData } from './localization'
import { DeviceCardChild } from '../../types'
import { Icon } from '@juullabs/react-components'

export interface UnauthenticatedProps extends DeviceCardChild {
  /** action for the button on the unauthenticated card state */
  onClick(): void
}

export const Unauthenticated: FC<UnauthenticatedProps> = ({
  inactive = false,
  onClick,
}) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false)
  const { button, subtitle, title } = unauthenticatedData

  const handleOnClick = async() => {
    setActionLoading(true)
    await onClick()
    setActionLoading(false)
  }

  return (
    <>
      <div>
        <Icon
          className={styles.icon}
          color='titanium|base|100'
          name='icon/16x16/alert_outlined'
        />
        <Text
          color='titanium|base|100'
          margin={{ top: 'baseTight' }}
          message={title}
          tagName='p'
          typography='bodyBaseBold'
        />
        <Text
          color='titanium|base|100'
          margin={{ top: 'extremeTight' }}
          message={subtitle}
          tagName='p'
          typography='bodySmall'
        />
      </div>
      <DeviceButton
        {...button}
        disabled={inactive}
        loading={actionLoading}
        onClick={handleOnClick}
        outline
        size='small'
      />
    </>
  )
}
