import { bugsnagClient } from 'utils/bugsnag'
import { GadgetConnectionStatus, InterfaceType } from 'types/gadget'
import { getIsPreProdEnvironment } from './app'
import { WebAppCards } from 'analytics-events'

type GadgetAction =
  | 'beep'
  | 'connect'
  | 'disableChargedBeepable'
  | 'disconnect'
  | 'enableChargedBeepable'
  | 'lock'
  | 'reauthenticate'
  | 'unlock'
  | 'unpair'

export const getGadgetAction = (
  action: () => void,
  actionType: GadgetAction,
) => (async() => {
  try {
    await action()
  } catch (error) {
    const errorType = `${actionType}_failed`
    bugsnagClient?.notify?.({ message: `${error}`, name: errorType })

    // eslint-disable-next-line no-console
    if (getIsPreProdEnvironment()) console.error(errorType, error)
  }
})

export const getInterfaceType = (
  connectionStatus: string,
  insecurelyConnected: boolean,
): InterfaceType => {
  if (insecurelyConnected && connectionStatus !== GadgetConnectionStatus.Disconnected) {
    return InterfaceType.Unauthenticated
  } else if (connectionStatus === GadgetConnectionStatus.Connected) {
    return InterfaceType.Connected
  } else if (connectionStatus === GadgetConnectionStatus.Intermediate) {
    return InterfaceType.Reconnecting
  } else {
    return InterfaceType.Disconnected
  }
}

export const getDeviceAnalyticState = (
  interfaceType: InterfaceType,
  hasAlert = false,
): WebAppCards.DeviceCardState => {
  switch (interfaceType) {
  case InterfaceType.Connected:
    return WebAppCards.DeviceCardState.Connected
  case InterfaceType.Disconnected:
    if (hasAlert === null) return null
    else return hasAlert
      ? WebAppCards.DeviceCardState.DisconnectedWithAlert
      : WebAppCards.DeviceCardState.Disconnected
  case InterfaceType.Reconnecting:
    return WebAppCards.DeviceCardState.Reconnecting
  case InterfaceType.Unauthenticated:
    return WebAppCards.DeviceCardState.Unauthenticated
  }
}
