import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './Section.module.scss'
import { Text, TextFormats } from 'components/Text'
import { SectionLink } from './SectionLink'
import { SectionToggle } from './SectionToggle'
import {
  UnstyledButtonProps,
  UnstyledLinkProps,
} from 'components/UnstyledPrimitives'
import { classNames } from 'utils/css'

export interface SectionProps extends ComponentPropsWithoutRef<'div'> {
  /** title of the section */
  heading: TextFormats
  /** are the section contents hidden/collapsed, only use with toggleButton */
  isHidden?: boolean
  /** link button params */
  link?: UnstyledLinkProps
  /** show/hide button params */
  toggleButton?: UnstyledButtonProps
}

export const Section: FC<SectionProps> = ({
  className,
  children,
  isHidden,
  link,
  heading,
  toggleButton,
  ...rest
}) => (
  <section className={classNames(styles.Section, className)} {...rest}>
    <header className={styles.header}>
      <Text
        className={styles.heading}
        message={heading}
        tagName='h2'
      />
      {link && <SectionLink {...link} />}
      {toggleButton && <SectionToggle isHidden={isHidden} {...toggleButton} />}
    </header>
    <div className={styles.animateContent}>
      {!isHidden && (
        <div className={styles.body}>
          {children}
        </div>
      )}
    </div>
  </section>
)
