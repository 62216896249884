import { TextFormats } from 'components/Text'
import { DeviceButtonProps } from '../DeviceButton'
import { WebAppScreens } from 'analytics-events'

type NewDeviceCardData = {
  button: DeviceButtonProps
  heading: TextFormats
  instruction: TextFormats
}

export const newDeviceCardData: NewDeviceCardData = {
  button: {
    clickEvent: WebAppScreens.Home.ClickConnect_NewDeviceCard(),
    content: 'Connect',
  },
  heading: { content: 'Pair a JUUL2 Device.' },
  instruction: { content: 'Connect to a new or forgotten JUUL2 Device.' },
}
