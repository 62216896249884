import React, { FC } from 'react'
import styles from './NoOrders.module.scss'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { Text } from 'components/Text'
import { LinkButton } from 'components/Button'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'
import { WebAppScreens } from 'analytics-events'

export interface NoOrdersProps extends TileProps {
  onClick?: () => void
}

export const NoOrders: FC<NoOrdersProps> = ({ onClick }) => (
  <Tile className={styles.NoOrders}>
    <Text
      margin={{ bottom: 'baseLoose' }}
      message={{ content: 'You have no recent orders.' }}
      tagName='p'
      typography='bodyBase'
    />
    <LinkButton
      className={styles.linkButton}
      clickEvent={WebAppScreens.Home.ClickShopJUUL_NoOrdersCard()}
      content='Shop JUUL'
      href={MobilecloudLink.Shop}
      primary='black'
      onClick={onClick}
      openInNewTab
      size='small'
    />
  </Tile>
)
