import React, { FC } from 'react'
import styles from './Disconnected.module.scss'
import { Text } from 'components/Text'
import { DeviceButton } from '../DeviceButton'
import { disconnectedData } from './localization'
import { Icon } from '@juullabs/react-components'
import { DeviceCardChild } from '../../types'
import { LastConnectedData } from '../../hooks/use-last-connected'

export interface DisconnectedProps extends DeviceCardChild, LastConnectedData {
  /** action for the button on the disconnected card state */
  onClick(): void
}

const ALERT_ICON = 'icon/16x16/notification-alert'

export const Disconnected: FC<DisconnectedProps> = ({
  hasAlert,
  inactive = false,
  onClick,
  timeSinceConnected,
}) => {
  const { alert, alertButton, button, caption } = disconnectedData

  return (
    <>
      <div>
        <div className={styles.captionWithIcon}>
          {hasAlert && <Icon name={ALERT_ICON} />}
          <Text
            color='titanium|base|100'
            message={caption}
            tagName='p'
            typography='bodyBaseBold'
            values={{ timeSinceConnected }}
          />
        </div>
        {hasAlert && (
          <Text
            color='titanium|base|100'
            margin={{ top: 'extremeTight' }}
            message={alert}
            tagName='p'
            typography='bodySmall'
          />
        )}
      </div>
      <DeviceButton
        disabled={inactive}
        onClick={onClick}
        {...(hasAlert ? alertButton : button)}
      />
    </>
  )
}
