import { DeviceCardSizes } from './Devices'
import { CarouselProps } from '../../components/Carousel'

// responsive carousel and screen configurations and types
export type CarouselResponsiveProps = Pick<CarouselProps,
  'allowTouchMove' | 'paginationStyle' | 'peepAdjacent' | 'spaceBetween'>

export type HomeViews = { size: DeviceCardSizes }

export const HOME_MEDIA_QUERIES = [
  '(min-width: 915px)',
  '(min-width: 769px)',
  '(min-width: 481px)',
  '(min-width: 388px)',
]

export const HOME_VIEWS: HomeViews[] = [
  { size: 5 },
  { size: 4 },
  { size: 3 },
  { size: 2 },
]

export const DEFAULT_HOME_VIEW: HomeViews = { size: 1 }

export const CAROUSEL_SLIDE_GAP = {
  default: 24,
  narrow: 8,
}
