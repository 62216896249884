import React, { FC, useState, useMemo } from 'react'
import { startOfWeek } from 'date-fns'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { InvalidInsight } from 'modules/UsageInsights/components'
import {
  PuffHistoryBarchart,
  PuffHistoryFormat,
} from 'modules/UsageInsights/charts/PuffHistoryBarchart'
import { WebAppInsights } from 'analytics-events'

const BARCHART_HEIGHT = 172

export const PuffsThisWeek: FC<TileProps> = ({ ...rest }) => {
  // todo: Add improved loading logic/ui to prevent flashing chart before valid status is known.
  const [isInvalid, setIsInvalid] = useState<boolean>(null)

  const puffsThisWeekEvent = useMemo(() => {
    if (isInvalid === null) return null

    return WebAppInsights.PuffsThisWeekEvent({ isValid: !isInvalid })
  }, [isInvalid])

  return (
    <Tile tileEvent={puffsThisWeekEvent} {...rest}>
      <Tile.Heading
        color='graphica|base|100'
        content='Puffs this week'
        margin={{ bottom: 'baseLoose' }}
      />
      <PuffHistoryBarchart
        chartFormat={PuffHistoryFormat.SevenDay}
        height={BARCHART_HEIGHT}
        setIsValid={(isValid) => setIsInvalid(!isValid)}
        startDate={startOfWeek(new Date())}
      />
      {isInvalid && <InvalidInsight />}
    </Tile>
  )
}
