import React, { FC } from 'react'
import { useSwiperSlide } from 'swiper/react'
import styles from './SurveyCard.module.scss'
import { classNames } from 'utils/css'
import { Tile, TileProps } from '../Tile'
import { Text, TextFormats } from 'components/Text'
import { Box } from 'components/Box'
import { Button, ButtonProps } from 'components/Button'
import { Event } from 'analytics-events'

export type Survey = {
  options?: ButtonProps[]
  prompt: TextFormats
  surveyCardEvent?: Event
}

export interface SurveyCardProps extends TileProps, Survey {
  onSurveyOptionClick(): void
}

export const SurveyCard: FC<SurveyCardProps> = ({
  surveyCardEvent,
  className,
  onSurveyOptionClick,
  options,
  prompt,
  ...rest
}) => {
  const { isActive } = useSwiperSlide()

  return (
    <Tile
      className={classNames(styles.SurveyCard, className)}
      tileEvent={isActive && surveyCardEvent}
      {...rest}
    >
      <Box className={styles.content}>
        <Text
          align='center'
          message={prompt}
          tagName='h3'
          typography='h4Mobile'
        />
        {options?.length && (
          <Box className={styles.options} margin={{ top: 'baseLoose' }}>
            {options.map((option, i) => (
              <Button
                design='relaxed'
                key={'option-' + i}
                onClick={onSurveyOptionClick}
                outline
                {...option}
              />
            ))}
          </Box>
        )}
      </Box>
    </Tile>
  )
}
