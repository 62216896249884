import React, {
  FC,
  useState,
  useEffect,
  ComponentPropsWithoutRef,
} from 'react'
import { WebAppCarousel, WebAppScreens, WebAppCards } from 'analytics-events'
import { Carousel } from 'components/Carousel'
import { Section } from '../components/Section'
import { SurveyCard } from '../components/SurveyCard'
import { surveys } from './components/ClaimsSurvey'
import { ClaimsCard, claims } from './components/ClaimsCard'
import { CarouselResponsiveProps } from '../config'
import { useLocalStorage } from 'hooks'

export const CLAIMS_SHOULD_HIDE = 'claims-should-hide'
export const CLAIMS_SURVEY_INDEX = 'claims-survey-index'

export interface ClaimsProps extends ComponentPropsWithoutRef<'div'> {
  carouselResponsiveProps: CarouselResponsiveProps
}

export const Claims: FC<ClaimsProps> = ({ carouselResponsiveProps }) => {
  // local storage
  const [isClaimsHidden, setIsClaimsHidden]
    = useLocalStorage<boolean>(CLAIMS_SHOULD_HIDE, false)
  const [surveyIndex, setSurveyIndex]
    = useLocalStorage<number>(CLAIMS_SURVEY_INDEX, 0)

  // state
  const [shouldIncludeSurvey, setShouldIncludeSurvey] = useState(false)

  // variables
  const sectionHeading = 'JUUL2 Facts'

  // effects
  useEffect(() => {
    if (surveyIndex < 2) setShouldIncludeSurvey(true)
  }, [])

  return (
    <Section
      heading={{ content: sectionHeading }}
      toggleButton={{
        clickEvent: WebAppScreens.Home.ToggleJuul2Facts({ enabled: !isClaimsHidden }),
        onClick: () => setIsClaimsHidden(!isClaimsHidden),
      }}
      isHidden={isClaimsHidden}
    >
      <Carousel
        {...carouselResponsiveProps}
        accessibilityLabelModifier={sectionHeading}
        carouselEventParams={{
          carouselName: WebAppCarousel.CarouselName.Juul2Facts,
          screenName: WebAppCarousel.ScreenName.Home,
        }}
        slides={[
          ...claims.map((claim, index) => (
            <ClaimsCard
              key={index}
              data-analytics-id={WebAppCards.CardName.ClaimsCard}
              {...claim}
            />
          )),
          shouldIncludeSurvey && (
            <SurveyCard
              key='claims-survey'
              data-analytics-id={WebAppCards.CardName.ClaimsSurveyCard}
              onSurveyOptionClick={() => setSurveyIndex(surveyIndex + 1)}
              {...surveys[surveyIndex]}
            />
          ),
        ]}
      />
    </Section>
  )
}
