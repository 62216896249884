import React, { FC, useEffect } from 'react'
import { classNames } from 'utils/css'
import styles from './Tile.module.scss'
import { Box, BoxProps } from 'components/Box'
import { Heading, HeadingProps } from './Heading'
import { Info, InfoProps } from './Info'
import { Spinner } from 'components/Spinner'
import { Icon } from '@juullabs/react-components'
import { Text, TextFormats } from 'components/Text'
import { Event } from 'analytics-events'
import { useAnalytics } from 'modules/AnalyticsProvider'

export interface TileProps extends BoxProps {
  error?: boolean
  loading?: boolean
  tileEvent?: Event
}

export interface TileItems {
  Heading: FC<HeadingProps>
  Info: FC<InfoProps>
}

const errorMessage: TextFormats
  = { content: 'There was an error while loading this content.' }

export const Tile: FC<TileProps> & TileItems = ({
  children,
  className,
  error,
  loading,
  tileEvent,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()

  useEffect(() => {
    if (!tileEvent) return

    reportEvent(tileEvent)
  }, [tileEvent])

  return (
    <Box
      className={classNames(
        styles.Tile,
        error && styles.error,
        loading && styles.loading,
        className,
      )}
      tagName='article'
      {...rest}
    >
      {(() => {
        if (error) {
          return (
            <div className={styles.errorContent}>
              <Icon
                name='icon/48x48/alert_outlined'
                color='ink|base|50'
              />
              <Text
                align='center'
                color='ink|base|50'
                message={errorMessage}
                tagName='p'
                typography='bodyBase'
              />
            </div>
          )
        } else if (loading) {
          return <Spinner color='dark' />
        } else {
          return children
        }
      })()}
    </Box>
  )
}

Tile.Heading = Heading
Tile.Info = Info
