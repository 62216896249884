import React, { FC, useState, useEffect, useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { Section } from '../components/Section'
import { NoOrders } from './components/NoOrders'
import { AutoShipCTA } from './components/AutoShipCTA'
import { StoresNearYou } from './components/StoresNearYou'
import { MostRecent } from './components/MostRecent'
import { AutoShip, AutoShipStatus } from './components/AutoShip'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'
import { FetchStatus, MostRecentOrderState, SubscriptionState } from './types'
import { fetchMostRecentOrderData, fetchSubscriptionData } from './utils'
import { Tile } from 'modules/Home/components/Tile'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { WebAppScreens, WebAppCards } from 'analytics-events'

export const Orders: FC = () => {
  // global state
  const { locale } = useConxSdk()

  // local state
  const [
    mostRecentOrder,
    setMostRecentOrder,
  ] = useState<MostRecentOrderState>({ status: FetchStatus.LOADING })
  const [
    subscription,
    setSubscription,
  ] = useState<SubscriptionState>({ status: FetchStatus.LOADING })

  // fetch data
  useEffect(() => {
    fetchMostRecentOrderData(setMostRecentOrder)
    fetchSubscriptionData(setSubscription)
  }, [])

  // error analytics events
  const subscriptionErrorEvent = useMemo(() => (
    WebAppCards.SubscriptionCardEvent({
      cardState: WebAppCards.CardState.Error,
      screenName: WebAppCards.ScreenName.Home,
    })
  ), [])

  const mostRecentOrderErrorEvent = useMemo(() => (
    WebAppCards.MostRecentOrderCardEvent({
      cardState: WebAppCards.CardState.Error,
      screenName: WebAppCards.ScreenName.Home,
    })
  ), [])

  // render orders card
  const renderMostRecent = () => {
    switch (mostRecentOrder.status) {
    case FetchStatus.LOADING:
      return <Tile loading />
    case FetchStatus.ERROR:
      return <Tile error tileEvent={mostRecentOrderErrorEvent} />
    case FetchStatus.NOT_FOUND:
      return <NoOrders />
    case FetchStatus.SUCCESS: {
      const { completedAt, lineItems, number, state } = mostRecentOrder.payload
      return (
        <MostRecent
          contents={lineItems}
          date={format(parseISO(completedAt), 'PP')}
          number={number}
          status={state}
        />
      ) }
    }
  }

  // render subscription card
  const renderSubscription = () => {
    switch (subscription.status) {
    case FetchStatus.LOADING:
      return <Tile loading />
    case FetchStatus.ERROR:
      return <Tile error tileEvent={subscriptionErrorEvent} />
    case FetchStatus.NOT_FOUND:
      return <AutoShipCTA locale={locale} />
    case FetchStatus.SUCCESS: {
      const { errors, quote, nextOrderDate } = subscription.payload
      return (
        <AutoShip
          locale={locale}
          nextOrderDate={format(parseISO(nextOrderDate), 'PP')}
          status={errors?.length ? AutoShipStatus.Error : AutoShipStatus.Active}
          total={quote.total_formatted}
        />
      ) }
    }
  }

  return (
    <Section
      heading={{ content: 'Your Orders' }}
      link={{
        clickEvent: WebAppScreens.Home.ClickViewAll_YourOrders(),
        href: MobilecloudLink.Account,
        openInNewTab: true,
      }}>
      {renderMostRecent()}
      {renderSubscription()}
      <StoresNearYou />
    </Section>
  )
}
