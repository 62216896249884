import { TextFormats } from 'components/Text'
import { DeviceButtonProps } from '../DeviceButton'
import { WebAppScreens } from 'analytics-events'
import { classNamesFsExclude } from 'utils/css'

//// enums

export enum DeviceButtonIcons {
  Lock='icon/16x16/locked_standard_filled',
  Ring='icon/16x16/ring_my_device',
  Unlock='icon/16x16/unlocked_partial_filled',
}

//// types

type Description = {
  lowGauge?: TextFormats
  withEstimate: TextFormats
  withoutEstimate: TextFormats
}

type BatteryBox = {
  description: Description
  heading: TextFormats
  subHeading: TextFormats
}

type PodBoxHeading = {
  flavorUnavailable: string
  podError: string
  withoutPod: string
}

type PodBox = {
  description: Description
  heading: PodBoxHeading
  subHeading: TextFormats
}

type ConnectedData = {
  batteryBox: BatteryBox
  lockButton: DeviceButtonProps
  podBox: PodBox
  ringButton: DeviceButtonProps
  unlockButton: DeviceButtonProps
}

//// data

const batteryBoxData: BatteryBox = {
  description: {
    withEstimate: [
      { content: 'Estimated: ' },
      { className: classNamesFsExclude(), content: '%{estimatedPuffsOnCharge}', tagName: 'span' },
      { content: ' puffs left on charge.' },
    ],
    withoutEstimate: { content: 'Not enough data for est. puffs on charge.' },
  },
  heading: { content: 'Battery ' },
  subHeading: { content: '%{batteryLevel}%' },
}

const podBoxHeading: PodBoxHeading = {
  flavorUnavailable: 'Flavor unavailable',
  podError: 'Pod error',
  withoutPod: 'No JUUL2 pod detected',
}

const podBoxData: PodBox = {
  description: {
    lowGauge: { content: 'Estimated: Very low pod e‑liquid level.' },
    withEstimate: [
      { content: 'Estimated: ' },
      { className: classNamesFsExclude(), content: '%{estimatedPuffsOnPod}', tagName: 'span' },
      { content: ' puffs left on pod.' },
    ],
    withoutEstimate: { content: 'Not enough data for est. puffs on pod.' },
  },
  heading: podBoxHeading,
  subHeading: { content: '%{podStrength} JUUL2 pod' },
}

const lockButtonData: DeviceButtonProps = {
  accessibilityLabel: 'Lock',
  clickEvent: WebAppScreens.Home.ClickLock_Connected_DeviceCard(),
  content: 'Lock',
  icon: DeviceButtonIcons.Lock,
}

const unlockButtonData: DeviceButtonProps = {
  accessibilityLabel: 'Unlock',
  clickEvent: WebAppScreens.Home.ClickUnlock_Connected_DeviceCard(),
  content: 'Unlock',
  icon: DeviceButtonIcons.Unlock,
}

const ringButtonData: DeviceButtonProps = {
  accessibilityLabel: 'Ring',
  clickEvent: WebAppScreens.Home.ClickRing_Connected_DeviceCard(),
  content: 'Ring',
  icon: DeviceButtonIcons.Ring,
}

export const connectedData: ConnectedData = {
  batteryBox: batteryBoxData,
  lockButton: lockButtonData,
  podBox: podBoxData,
  ringButton: ringButtonData,
  unlockButton: unlockButtonData,
}
