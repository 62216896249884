import React, { FC } from 'react'
import styles from './StoresNearYou.module.scss'
import { classNames } from 'utils/css'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { Text } from 'components/Text'
import { LinkButton } from 'components/Button'
import { Icon } from '@juullabs/react-components'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'
import { WebAppScreens } from 'analytics-events'

export interface StoresNearYouProps extends TileProps {
  onClick?: () => void
}

export const StoresNearYou: FC<StoresNearYouProps> = ({ onClick }) => (
  <Tile className={styles.StoresNearYou}>
    <div className={styles.content}>
      <Text
        message={{ content: 'Stores near you' }}
        tagName='h3'
        typography='h4'
      />
      <Text
        margin={{ bottom: 'baseTight', top: 'extremeTight' }}
        message={{ content: 'Find stores that carry JUUL2 products in your area.' }}
        tagName='p'
        typography='bodySmall'
      />
      <LinkButton
        className={styles.linkButton}
        clickEvent={WebAppScreens.Home.ClickFindAStoreNearMe_StoresNearYouCard()}
        content='Find a store near me'
        href={MobilecloudLink.StoreLocator}
        primary='blue'
        onClick={onClick}
        openInNewTab
        size='small'
      />
    </div>
    <div className={classNames(styles.locationIcons)}>
      <Icon
        className={classNames(styles.bluePin)}
        name='graphic/location-locator_pin-promotion-selected-48x48'
      />
      <Icon
        className={classNames(styles.location)}
        name='graphic/location-location_live-42x42'
      />
      <Icon
        className={classNames(styles.blackPin, styles.pin1)}
        name='graphic/location-locator_pin-standard-not_selected-48x48'
      />
      <Icon
        className={classNames(styles.blackPin, styles.pin2)}
        name='graphic/location-locator_pin-standard-not_selected-48x48'
      />
    </div>
    <Icon
      className={styles.mapBackground}
      name='graphic/location-map_general-blue'
    />
  </Tile>
)
