import React, {
  ComponentPropsWithoutRef,
  FC,
  ReactElement,
  useState,
  useCallback,
  useRef,
} from 'react'
import { useIsomorphicLayoutEffect, useSize, useThrottledValue } from 'hooks'

import { useAnalytics } from 'modules/AnalyticsProvider'
import { WebAppCarousel } from 'analytics-events'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'

import styles from './Carousel.module.scss'
import { classNames } from 'utils/css'

import { Button } from 'components/Button'
import { OverlayButton } from './OverlayButton'
import { Pagination, PaginationProps } from './Pagination'

export interface CarouselProps extends ComponentPropsWithoutRef<'div'> {
  /** navigation and pagination aria-label modifier for screen readers */
  accessibilityLabelModifier?: string
  /** allow ability to swipe though with mouse or by touch */
  allowTouchMove?: boolean
  /** static identifying parameters for carousel events */
  carouselEventParams?: {
    /** name of the carousel instance */
    carouselName: WebAppCarousel.CarouselName
    /** name of the screen the carousel renders on */
    screenName: WebAppCarousel.ScreenName
  }
  /** custom className */
  className?: string
  /** pagination button style:
   *  dots are good for desktop, where dashes are better for touch screens */
  paginationStyle?: PaginationProps['paginationStyle']
  /** when true the carousel view window includes a hint of the adjacent slides,
   * which then double as buttons */
  peepAdjacent?: boolean
  /** slides in the carousel */
  slides: ReactElement[]
  /** represents the space between two slides [Note: when peepAdjacent is true,
   * the visible width of the adjacent slides will be equal to the space between slides] */
  spaceBetween?: number
  /** carousel transition speed in MS */
  speed?: number
}

export const Carousel: FC<CarouselProps> = ({
  accessibilityLabelModifier,
  allowTouchMove = false,
  carouselEventParams,
  className,
  paginationStyle = 'dots',
  peepAdjacent = false,
  slides,
  spaceBetween = 24,
  speed = 400,
  ...rest
}) => {
  // hooks
  const { reportEvent } = useAnalytics()

  // data
  const carouselSlides = slides.filter(Boolean)
  const numberOfSlides = carouselSlides.length
  const totalMargin = 4 * spaceBetween

  // refs
  const carouselRef = useRef<(HTMLDivElement)>()
  const [swiper, setSwiper] = useState<SwiperCore>(null)

  // state
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [carouselWidth, setCarouselWidth] = useState<number>(0)

  // resize
  const size = useSize(carouselRef)
  const throttledSize = useThrottledValue({ throttleMs: 300, value: size })

  // accessibility
  const buildAccessibilityLabel = (prefix = '') => (
    [prefix, accessibilityLabelModifier, 'Slide'].filter(Boolean).join(' ')
  )

  const nextButtonLabel = buildAccessibilityLabel('Next')
  const prevButtonLabel = buildAccessibilityLabel('Previous')
  const paginationLabelPrefix = buildAccessibilityLabel()

  // functions
  const getInnerCarouselStyles = () => {
    if (!peepAdjacent) return { width: '100%' }
    return {
      marginLeft: `-${totalMargin/2}px`,
      width: `calc(100% + ${totalMargin}px)`,
    }
  }

  const getSlidesPerView = () => {
    if (!peepAdjacent || !carouselWidth) return 1
    return (carouselWidth + totalMargin) / carouselWidth
  }

  const handlePrev = useCallback(() => {
    swiper?.slidePrev()
  }, [swiper])

  const handleNext = useCallback(() => {
    swiper?.slideNext()
  }, [swiper])

  const handlePagination = useCallback((slideId: number) => {
    swiper?.slideTo(slideId)
  }, [swiper])

  // analytic functions
  const getSlideName = (swiper: SwiperCore) => (swiper
    ?.slides[swiper?.activeIndex]
    ?.firstElementChild
    ?.getAttribute('data-analytics-id')
  )

  const reportSlideChange = useCallback((swiper: SwiperCore) => {
    if (!carouselEventParams) return

    reportEvent(WebAppCarousel.CarouselSlideChangeEvent({
      ...carouselEventParams,
      activeIndex: swiper?.activeIndex,
      previousIndex: swiper?.previousIndex,
      slideName: getSlideName(swiper),
      totalSlides: swiper?.slides.length,
    }))
  }, [])

  // effects
  useIsomorphicLayoutEffect(() => {
    if (!swiper) return

    setCarouselWidth(carouselRef?.current?.offsetWidth)
    swiper.update()

  }, [peepAdjacent, throttledSize?.width, Boolean(size)])

  return (
    <div
      className={classNames(styles.Carousel, className)}
      ref={carouselRef}
      {...rest}
    >
      <div
        className={styles.innerCarousel}
        style={{ ...getInnerCarouselStyles() }}
      >
        {!peepAdjacent && (
          <>
            {!swiper?.isBeginning && (
              <Button
                accessibilityLabel={prevButtonLabel}
                icon='icon/24x24/chevron_large_left'
                className={styles.leftArrow}
                onClick={handlePrev}
              />
            )}
            {!swiper?.isEnd && (
              <Button
                accessibilityLabel={nextButtonLabel}
                icon='icon/24x24/chevron_large_right'
                className={styles.rightArrow}
                onClick={handleNext}
              />
            )}
          </>
        )}
        <Swiper
          onSwiper={setSwiper}
          onActiveIndexChange={swiper => setActiveIndex(swiper?.activeIndex)}
          onSlideChange={reportSlideChange}
          slidesPerView={getSlidesPerView()}
          spaceBetween={spaceBetween}
          centeredSlides={true}
          speed={speed}
          allowTouchMove={allowTouchMove}
          wrapperClass={styles.swiperWrapper}
        >
          {carouselSlides.map(slide => (
            <SwiperSlide
              className={styles.swiperSlide}
              key={`slide-${slide.key}`}
            >
              {({ isNext, isPrev }) => (
                <>
                  {peepAdjacent && (
                    <>
                      {isPrev && (
                        <OverlayButton
                          accessibilityLabel={prevButtonLabel}
                          extendedAreaDirection='right'
                          extendedAreaWidth={spaceBetween}
                          onClick={handlePrev}
                        />
                      )}
                      {isNext && (
                        <OverlayButton
                          accessibilityLabel={nextButtonLabel}
                          extendedAreaDirection='left'
                          extendedAreaWidth={spaceBetween}
                          onClick={handleNext}
                        />
                      )}
                    </>
                  )}
                  {slide}
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {numberOfSlides > 1 && (
        <Pagination
          accessibilityLabelPrefix={paginationLabelPrefix}
          activeIndex={activeIndex}
          handlePagination={handlePagination}
          numberOfSlides={numberOfSlides}
          paginationStyle={paginationStyle}
        />
      )}
    </div>
  )
}
