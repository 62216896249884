import React, { FC, ComponentPropsWithoutRef } from 'react'
import { useRouter } from 'next/router'
import Routes from 'types/routes'
import { Section } from '../components/Section'
import { Carousel } from 'components/Carousel'
import { NewDeviceCard } from './components/NewDeviceCard'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { DeviceCard } from './components/DeviceCard/DeviceCard'
import { getInterfaceType } from 'utils/gadget'
import { WebAppCarousel, WebAppCards } from 'analytics-events'
import { CarouselResponsiveProps } from '../config'
import { DeviceCardSizes } from './types'

export interface DevicesProps extends ComponentPropsWithoutRef<'div'> {
  carouselResponsiveProps: CarouselResponsiveProps
  size: DeviceCardSizes
}

export const Devices: FC<DevicesProps> = ({
  carouselResponsiveProps,
  size,
}) => {
  // hooks
  const router = useRouter()
  const { gadgetsList } = useDeviceConnection()

  // variables
  const sectionHeading = 'My Devices'

  return (
    <Section heading={{ content: sectionHeading }}>
      <Carousel
        {...carouselResponsiveProps}
        accessibilityLabelModifier={sectionHeading}
        carouselEventParams={{
          carouselName: WebAppCarousel.CarouselName.MyDevices,
          screenName: WebAppCarousel.ScreenName.Home,
        }}
        slides={[
          ...gadgetsList.map(({
            beepable,
            connectable,
            connectionStatus,
            hardwareId,
            insecurelyConnected,
            juulInfo,
            lastConnectedAt,
            lockable,
            podInfo,
            reauthenticate,
          }) => (
            <DeviceCard
              key={'gadget-' + hardwareId}
              batteryLevel={juulInfo?.batteryLevel}
              charging={juulInfo?.isChargerConnected}
              deviceCardEventParams={{ screenName: WebAppCards.ScreenName.Home }}
              deviceName={juulInfo.name}
              disconnectDevice={async() => connectable?.disconnect()}
              hasPod={juulInfo?.hasPod}
              interfaceType={getInterfaceType(connectionStatus, insecurelyConnected)}
              lastConnected={lastConnectedAt}
              liquidLevel={podInfo?.usage}
              lockDevice={async() => lockable?.lock()}
              locked={juulInfo?.isLocked}
              podStatus={podInfo?.status}
              puffsOnCharge={juulInfo?.batteryEstimate}
              puffsOnPod={podInfo?.podConsumptionEstimate}
              reauthenticateDevice={async() => reauthenticate()}
              recipe={podInfo?.recipe}
              reconnectDevice={async() => connectable?.connect()}
              ringDevice={async() => beepable?.beep()}
              serialNumber={juulInfo.serialNumber}
              size={size}
              unlockDevice={async() => lockable?.unlock()}
            />
          )),
          <NewDeviceCard
            key='new-gadget'
            connectNewDevice={() => router.push(Routes.Pairing)}
            newDeviceCardEventParams={{ screenName: WebAppCards.ScreenName.Home }}
            size={size}
          />,
        ]}
      />
    </Section>
  )
}
