import React, { FC } from 'react'
import styles from './SectionToggle.module.scss'
import { Icon } from '@juullabs/react-components'
import {
  UnstyledButton,
  UnstyledButtonProps,
} from 'components/UnstyledPrimitives'

interface SectionToggleProps extends UnstyledButtonProps {
  isHidden: boolean
}

export const SectionToggle: FC<SectionToggleProps> = ({
  isHidden,
  disabled,
  ...rest
}) => {
  const toggleText = isHidden ? 'Show' : 'Hide'

  return (
    <UnstyledButton
      aria-label={toggleText}
      className={styles.SectionToggle}
      disabled={disabled}
      {...rest}
    >
      <span>{toggleText}</span>
      <Icon
        color={disabled ? 'steel|base|30' : 'ink|base|100'}
        name={isHidden ? 'icon/16x16/expand' : 'icon/16x16/collapse'}
      />
    </UnstyledButton>
  )
}
