
// Gadget Enums

export enum GadgetConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Intermediate = 'intermediate',
}

export enum InterfaceType {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Reconnecting = 'reconnecting',
  Unauthenticated = 'unauthenticated'
}

export enum WarrantySyncStatus {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}

export enum WarrantyInterface {
  Active = 'active',
  Expired = 'expired',
  Inactive = 'inactive',
}

// Gadget Constants

export const MAX_DEVICE_NAME_LENGTH = 15
